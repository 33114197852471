import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../styles/about.css"

const About = () => (
  <Layout>
    <Seo title="About" keywords={[`assignmentopetator.com`, `Assignment Operator`,]}/>
    <div id="aboutContainer">
      {/* To display the image */}
      <div id="aboutImageContainer">
        <StaticImage
          src="../images/example.png"
          loading="eager"
          width={64}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          style={{ marginBottom: `3px` }}
        />
      </div>
      <div id="aboutText">
        <p>
          We are <span className="specialWord"><Link to="/" className="linkAbout">Assignment Operator</Link></span>!
        </p>    
        <p>
          We operate <b>data-centric</b> assignment based on state-of-the-art technology, programming, and data science principle.
        </p>              
        <p>
          We are avid learner. We eager to learn insights of your data. 
          We believe <em>data</em> always carry knowledge. 
          We wanna learn the knowledge for you!
        </p>
        <p>
          To see the list of different services we provide, 
          and to know what kinds of assignment we operate, please check our <Link to="/service/" className="linkAbout">Services</Link>.
        </p>
        <p>
          So, GET IN TOUCH! We would love to hear from you. To write us just fill-up the <Link to="/contact/" className="linkAbout">Contact</Link> form and send your message. We will get back to you ASAP!
        </p>  
        <p>Thanks for your time and interest.</p>
        <p><span className="aboutCompanyName"><Link to="/" className="linkAbout">Assignment Operator</Link></span></p>
      </div>
      <div id="aboutSlogan">
          _ a Trusted Team-mate of your Assignment _
      </div>
    </div>
</Layout>
)

export default About

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore assignmentopetator